<template>
	<div>
		<div>
			<slot name="logo" />
		</div>

		<div>
			<slot />
		</div>
	</div>
</template>
